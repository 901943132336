function NavbarProfilerController(Auth, $scope, $location, $rootScope) {
  this.userName = Auth.user.name;
  this.showMore = false;

  this.loggedIn = Auth.isLoggedIn;

  // Implicit return, sor reads () => {return Auth.isLoggedIn() && !Auth.isGuest()}
  this.showUserControls = () => Auth.isLoggedIn() && !Auth.isGuest();

  $scope.$watchCollection(() => Auth.user, (newValue) => {
    this.userName = newValue.name;
    this.showLoggedInControls = Auth.isLoggedIn() && !Auth.isGuest();
  });

  this.logout = () => {
    Auth.logout();
    $location.path('/');
  };

  $rootScope.$on('$routeChangeStart', ($event, newUrl, oldUrl) => {
    this.showMore = false;
  });
}

angular.module('sq.navbar.profile.component', [])
.component('navbarProfile', {
  controller: NavbarProfilerController,
  templateUrl: 'navbar/profile/navbar.profile.tpl.html',
});
